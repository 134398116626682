<template>
  <div class="login-container">
    <div class="login-box">
      <img
        src="https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/moble/4.png"
        alt=""
        srcset=""
      />
      <div class="qie-box" @click="changeQie" v-if="showQie">
        <img :src="qieImg" alt="" srcset="">
      </div>

      <keep-alive>
      <router-view></router-view>
      </keep-alive>

      <div class="mei-left" v-if="showQr"></div>
      <div class="mei-right" v-if="showQr"></div>
    </div>

    <div class="tips-box" v-if="showQr"></div>
  </div>
</template>

<script>
export default {
  name: "Login",
  components: {},
  data() {
    return {

    };
  },
  computed: {
    qieImg(){
      let url
      switch (this.$route.path) {
          case "/loginMoble/account":
            url = 'https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/qie2.png';
            break;
            case "/loginMoble/qrcode":
            url = 'https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/qie1.png';
            break;
      }
      return url;
    },
    showQr(){
      if(this.$route.path=="/loginMoble/qrcode"){
        return true
      }else{
        return false
      }
    },
    showQie(){
      if(this.$route.path=="/loginMoble/reg"){
        return false
      }else{
        return true
      }
    }
  },
  mounted() {
    if (
      navigator.userAgent.match(
        /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
      )
    ) {
      //显示移动端的代码
      console.log("移动端");
      this.$router.push("/loginMoble");
    } else {
      //显示非移动端的代码
      console.log("pc端");
      this.$router.push("/login/qrcode");
    }
  },
  methods: {
    changeQie() {
      if(this.$route.path=="/loginMoble/account"){
        this.$router.push("/loginMoble/qrcode");
      }else{
        this.$router.push("/loginMoble/account");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login-container {
  position: relative;
  height: 100vh;
  background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/moble/1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  overflow: hidden;

  .login-box {
    width: 90%;
    height: 70vh;
    background-color: rgba(20, 42, 72);
    margin: 0 auto;
    margin-top: 15vh;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    .qie-box {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      z-index: 9;
      width: 62px;
      height: 62px;
    }
    img {
      width: 100%;
    }

    .mei-left {
      width: 100px;
      height: 110px;
      position: absolute;
      background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/mei-left.png);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      bottom: -20px;
      left: -20px;
    }
    .mei-right {
      width: 100px;
      height: 100px;
      position: absolute;
      background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/mei-right.png);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      bottom: -20px;
      right: -20px;
    }
  }

  .tips-box {
    width: 370px;
    height: 117px;
    margin: 0 auto;
    background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/moble/2.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    z-index: 99;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
